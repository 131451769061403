import { useState } from 'react';
import html2pdf from 'html2pdf.js';
import UpperLogo from './video/logo.mp4';
// import LowerLogoImg from './images/LowerLogo.png';
// import Clip from './video/clip.gif';
// import ClipSend from './video/clip_send.gif';
import ReportContent from './components/report/ReportContent';
import Speedometer from './components/speedometer/Speedometer';
import ReactDOMServer from 'react-dom/server';
import {
  subscribe,
  retrieveLaunchParams,
  // postEvent,
} from '@telegram-apps/sdk-react';
// import { initMiniApp } from '@telegram-apps/sdk';
import './App.css';
import { api } from './api/api';
import MainScreen from './screens/MainScreen/MainScreen';
import PreliminaryReportScreen from './screens/PreliminaryReportScreen/PreliminaryReportScreen';

// const [miniApp] = initMiniApp();

function getArrayBuffer(data) {
  let len = data.length,
    ab = new ArrayBuffer(len),
    u8 = new Uint8Array(ab);

  while (len--) u8[len] = data.charCodeAt(len);
  return ab;
}

function getBlob(data) {
  return new Blob([getArrayBuffer(data)], {
    type: 'application/pdf',
  });
}

const App = () => {
  const { initData } = retrieveLaunchParams();

  const [selectedCurrency, setSelectedCurrency] = useState('btc');
  const [isChecked, setIsChecked] = useState(false);
  const [riskLevel, setRiskLevel] = useState(null);
  const [phrases, setPhrases] = useState([]);
  const [isBought, setIsBought] = useState(false);
  const [loading, setLoading] = useState(false);
  const [report, setReport] = useState('');
  const [address, setAddress] = useState('');
  const [isByPage, setIsByPage] = useState(false);
  const [isError, setIsError] = useState(false);
  const [respData, setRespDta] = useState([]);

  const [showSpeedometer, setShowSpeedometer] = useState(false);
  const [speedometerValue, setSpeedometerValue] = useState(0);

  const listener = (event) => {
    if (event.name === 'invoice_closed' && event.payload.status === 'paid') {
      setIsBought(true);
    }
  };
  subscribe(listener);

  const handleDownload = async () => {
    const element = document.createElement('div');
    element.innerHTML = ReactDOMServer.renderToStaticMarkup(
      <ReportContent report={report} />,
    );
    const options = {
      filename: 'myDocument.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 1 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
    };

    const formData = new FormData();

    const exporter = new html2pdf();
    const pdf = await exporter.from(element).set(options).output();

    const file = getBlob(pdf);

    const newFile = new File([file], 'report.pdf', { type: 'application/pdf' });

    formData.append('file', newFile, 'report.pdf');
    formData.append('chat_id', initData?.user?.id);

    try {
      await api.post('bot/send_report', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    } catch (err) {
      console.log(err);
    }

    setIsChecked(false);
    setIsByPage(false);
    setIsBought(false);
    // miniApp.close();
  };

  // const handleBack = async () => {
  //   setShowSpeedometer(false);
  // };

  return (
    <>
      {showSpeedometer ? (
        <div className="speed-container">
          <div className="container-upperlogo">
            <video
              className="loader-container-video"
              style={{ marginTop: '30px' }}
              autoPlay
              muted
              loop
              playsInline
            >
              <source src={UpperLogo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>

          <Speedometer
            selectedCurrency={selectedCurrency}
            respData={respData}
            percent={speedometerValue}
            phrase={phrases}
            phrases={phrases}
            riskLevel={riskLevel}
            setIsBought={setIsBought}
            setLoading={setLoading}
            loading={loading}
            address={address}
            isBought={isBought}
            handleDownload={handleDownload}
            isError={isError}
            setIsError={setIsError}
            setAddress={setAddress}
          />
          {/* <div className="container-buttons">
            <button className="check-btn" onClick={handleBack}>
              ОБРАТНО
            </button>
            <img
              src={LowerLogoImg}
              style={{ marginBottom: '40px' }}
              alt="Logo"
            />
          </div> */}
        </div>
      ) : !isChecked ? (
        <MainScreen
          isError={isError}
          handleDownload={handleDownload}
          setIsBought={setIsBought}
          isBought={isBought}
          setSelectedCurrency={setSelectedCurrency}
          selectedCurrency={selectedCurrency}
          respData={respData}
          setRespDta={setRespDta}
          setIsChecked={setIsChecked}
          setLoading={setLoading}
          setPhrases={setPhrases}
          setReport={setReport}
          setRiskLevel={setRiskLevel}
          setSpeedometerValue={setSpeedometerValue}
          setShowSpeedometer={setShowSpeedometer}
          address={address}
          setAddress={setAddress}
          setIsByPage={setIsByPage}
          loading={loading}
          setIsError={setIsError}
        />
      ) : (
        isByPage && (
          <PreliminaryReportScreen
            phrases={phrases}
            riskLevel={riskLevel}
            setIsBought={setIsBought}
            setLoading={setLoading}
            loading={loading}
            address={address}
            isBought={isBought}
            handleDownload={handleDownload}
            isError={isError}
            setIsError={setIsError}
            setAddress={setAddress}
          />
        )
      )}
    </>
  );
};

export default App;
