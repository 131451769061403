import Ton from '../../../../images/ton.png';
import Not from '../../../../images/not.png';

import MCard from '../../../../images/mcard.png';
import USDT from '../../../../images/usdt.png';
import Star from '../../../../images/star.png';

export const monetItems = [
  {
    name: 'TON',
    count: '0.13',
    icon: Ton,
  },
  {
    name: 'Soon',
    count: '',
    icon: Not,
  },
  {
    name: 'Soon',
    count: '',
    icon: USDT,
  },
  {
    name: 'Soon',
    count: '',
    icon: MCard,
  },
  {
    name: 'Soon',
    count: '',
    icon: Star,
  },
];
