import React from 'react';
import './globals.css';
import './style.css';
import img1 from '../../images/01-1.png';
import img2 from '../../images/01-2.png';
import img5 from '../../images/1-5.png';
import qr from '../../images/qr.jpg';
import ReactMarkdown from 'react-markdown';

const ReportContent = ({ report }) => {
  return (
    <div className="a">
      <div className="div">
        <div className="header">
          <p className="text-wrapper">
            Отчет по анализу смарт-контракта на Solidity
          </p>
          <div className="qr-wrapper">
            <img src={qr} alt="qr" className="qr-image" />
            <p className="qr-description">отчёт проделан сервисом SMACOS</p>
          </div>
        </div>
        <div className="text-wrapper-2">Выявленные уязвимости и риски</div>
        <div className="text-wrapper-4">сканируй, защищай, инвестируй</div>
        <div className="SMACOS-2">
          отчёт проделан
          <br />
          сервисом SMACOS
        </div>
        <div className="SMACOS-3">
          отчёт проделан
          <br />
          сервисом SMACOS
        </div>
        <div className="SMACOS-4">
          отчёт проделан
          <br />
          сервисом SMACOS
        </div>
        <div className="SMACOS-5">
          отчёт проделан
          <br />
          сервисом SMACOS
        </div>
        <div className="SMACOS-6">
          отчёт проделан
          <br />
          сервисом SMACOS
        </div>
        <div className="overlap">
          <div className="element-reentrancy">
            <ReactMarkdown>{report}</ReactMarkdown>
          </div>
        </div>
        <img className="element" src={img1} alt="Diagram 1" />
        <img className="img" src={img2} alt="Diagram 2" />
        <div className="group">
          <div className="overlap-group">
            <div className="ellipse"></div>
            <div className="ellipse-2"></div>
            <div className="rectangle"></div>
          </div>
        </div>
        <img className="element-2" src={img5} alt="Diagram 3" />
        <img className="element-3" src={img5} alt="Diagram 4" />
        <img className="element-4" src={img5} alt="Diagram 5" />
        <img className="element-5" src={img5} alt="Diagram 6" />
        <img className="element-6" src={img5} alt="Diagram 7" />
      </div>
    </div>
  );
};

export default ReportContent;
