import './Speedometer.css';
import RiskAssessment from '../../components/RiskAssessment/RiskAssessment';

const Speedometer = ({
  selectedCurrency,
  respData,
  percent,
  phrases,
  riskLevel,
  setIsBought,
  setLoading,
  loading,
  address,
  isBought,
  handleDownload,
  isError,
  setIsError,
  setAddress,
}) => {
  return (
    <RiskAssessment
      selectedCurrency={selectedCurrency}
      respData={respData}
      address={address}
      phrases={phrases}
      isBought={true}
      type={'wallet'}
      riskLevel={riskLevel}
    />
  );
};

export default Speedometer;
