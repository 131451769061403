import React from 'react';
import { monetItems } from '../../UI/Modals/CardModal/constants';
import { TonConnectButton, useTonWallet } from '@tonconnect/ui-react';
import { Button } from '../../UI/Buttons/Button/Button';

const BuyView = ({ handleBuy, setIsOpenTonBtn, isOpenTon }) => {
  const wallet = useTonWallet();

  return (
    <div className="text-center text-white">
      {isOpenTon ? (
        <div className="flex flex-col items-center gap-[40px] justify-center h-[200px]">
          <TonConnectButton />
          <Button onClick={handleBuy} disabled={!wallet}>
            Купить
          </Button>
        </div>
      ) : (
        <>
          <h2 className="text-[32px] font-bold">Покупка</h2>
          <p className="mb-4 text-white">
            Daily Value Package <br />
            <span className="text-[32px] font-bold text-white">= $0.99</span>
          </p>
          <ul className="space-y-2 mb-4">
            {monetItems.map((item, index) => {
              const isTon = item.name === 'TON';
              return (
                <li
                  className="flex justify-center gap-3 p-5 bg-[#1C1C36] rounded-lg"
                  key={index}
                  onClick={isTon ? setIsOpenTonBtn : null}
                >
                  <img src={item.icon} alt="pict" />
                  <div>
                    <span>{item.count}</span>
                    <span>{item.name}</span>
                  </div>
                </li>
              );
            })}
          </ul>
        </>
      )}
    </div>
  );
};

export default BuyView;
